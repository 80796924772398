import { Grid, Typography } from '@mui/material';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import LoginContainer from './styles/LoginContainer';
import CommonButton from '@/components/common/CommonButton';
import Logo from '@/components/icons/Logo';
import LoginItemWrapper from '@/components/pages/Login/styles/LoginItemWrapper';
import LogoWrapper from '@/components/pages/Login/styles/LogoWrapper';
import { getSavedLogin } from '@/utils/functions/loginUtils';
import { useObservableLocalStorage } from '@/utils/hooks/useLocalStorage';
import { routePaths } from '@/utils/routePaths';
import { USER_TYPE } from '@/utils/types';

export const INNER_LOGIN_NAVIGATION = 'inner-login-navigation';

const Login = () => {
  const router = useRouter();
  const [, setIsSent] = useObservableLocalStorage('downgrade-message-sent');

  useEffect(() => {
    const login = getSavedLogin();
    if (!login) return;
    if (login === USER_TYPE.OWNER) {
      localStorage.setItem(INNER_LOGIN_NAVIGATION, 'true');
      router.push(routePaths.owner.login);
    } else if (login === USER_TYPE.CUSTOMER) {
      localStorage.setItem(INNER_LOGIN_NAVIGATION, 'true');
      router.push(routePaths.customer.login);
    }
  }, [router]);

  return (
    <LoginContainer>
      <Grid
        sx={{ width: '100%', maxWidth: '1130px' }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Typography variant="h1" sx={{ mt: 7.5, mb: 1.5 }}>
          Welcome to Ostana!
        </Typography>
        <Typography variant="p1" sx={{ mb: 5 }}>
          Please select the type of account you require.
        </Typography>
        <Grid display="flex" justifyContent="center" gap={2.5} container>
          <LoginItemWrapper
            onClick={() => {
              localStorage.setItem(INNER_LOGIN_NAVIGATION, 'true');
              router.push(routePaths.owner.login);
              setIsSent(false);
            }}
            sx={{
              backgroundImage: 'url("/images/businessSignBg.jpeg")',
              backgroundPosition: '-11.025px -41.596px',
              backgroundSize: '138.815% 82.892%',
              backgroundColor: 'lightgray',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Grid display="flex" flexDirection="column" sx={{ zIndex: 10 }}>
              <Typography
                variant="h4Bold"
                color="white.main"
                sx={{ textAlign: 'center', mb: 0.5 }}
              >
                Business
              </Typography>
              <Typography variant="p2" color="system.inputBorder">
                Tailored solutions for enterprises
              </Typography>
            </Grid>
            <CommonButton
              data-testid="business-sign-in"
              sx={{ width: '180px', zIndex: 10 }}
            >
              Sign In
            </CommonButton>
          </LoginItemWrapper>
          <LoginItemWrapper
            onClick={() => {
              localStorage.setItem(INNER_LOGIN_NAVIGATION, 'true');
              router.push(routePaths.customer.login);
              setIsSent(false);
            }}
            sx={{
              backgroundImage: 'url("/images/customerSignBg.jpg")',
              backgroundPosition: '-72.517px -4.343px',
              backgroundSize: '138.815% 80.385%',
              backgroundColor: 'lightgray',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Grid
              gap={1}
              display="flex"
              flexDirection="column"
              sx={{ zIndex: 10 }}
            >
              <Typography
                variant="h4Bold"
                color="white.main"
                sx={{ textAlign: 'center', mb: 0.5 }}
              >
                Customer
              </Typography>
              <Typography variant="p2" color="system.inputBorder">
                Personalized services for individuals
              </Typography>
            </Grid>
            <CommonButton sx={{ width: '180px', zIndex: 10 }}>
              Sign In
            </CommonButton>
          </LoginItemWrapper>
        </Grid>
      </Grid>
    </LoginContainer>
  );
};

export default Login;
