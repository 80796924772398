import { styled } from '@mui/material/styles';

import publicPath from '@/utils/functions/publicPath';

const LoginContainer = styled('div')(() => ({
  backgroundImage: `url(${publicPath('/images/loginBg.webp')})`,
  backgroundSize: 'cover',
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export default LoginContainer;
